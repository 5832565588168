import React from 'react'
import SectionTitle from '../SectionTitle'
import {Link} from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
// import image1 from '../../../images/gyms.jpg'

// import image2 from '../../../images/services.jpg'

import MyButton from "../../../ui/button/MyButton"
import Arrow from "../../../ui/btnArrow/Arrow"
import ButtonLinkWrapper from "../../../ui/btnLinkWrapper/ButtonLinkWrapper"


<StaticImage src="../../../images/socials.jpg" alt="join us" />

const About = ({
    sectionTitle,
    sectionTitleDesc,
    sectiontitleBtnTxt,
    sectiontitleBtnUrl,
    isBlackBg,
    clubstitle,
    clubsdescription,
    servicestitle,
    servicesdescription

}) => {
 
    return (
        <section id="about" className={isBlackBg? "black_bg" : "light_bg"}>
            <SectionTitle 
                isBlackBg={isBlackBg}
                title={sectionTitle}
                description={sectionTitleDesc}
                url={sectiontitleBtnUrl}
                urlBtnTxt={sectiontitleBtnTxt}
            />

            <div className="about__grid">

                <div  className="about__card">
                    <div className="vertical__title">
                        <p>
                        {clubstitle}
                        </p>
                    </div>
                    <div className="card__content border__right__light border__left__light border__bottom__light border__top__light">
                        {/* <img src={image1}  className="card__content__image" alt="НАШІ ЗАЛИ"/> */}

                        <StaticImage src="../../../images/gyms.jpg" alt="НАШІ ЗАЛИ" /> 


                        <div className='card__info border__right__light border__top__light'>

                            <h3>{clubsdescription}</h3>
                            <br/>
                        <Link to="/clubs" style={{textDecoration: "none"}}>
                        <ButtonLinkWrapper>
                        <MyButton isBlackBg={isBlackBg}>ПЕРЕГЛЯНУТИ</MyButton>
                            <Arrow isBlackBg={true} />
                        </ButtonLinkWrapper>
                    </Link>
                        </div>  
                    </div>
                </div>

                <div  className="about__card">
                    <div className="vertical__title">
                        <p>
                        {servicestitle}
                        </p>
                    </div>
                    <div className="card__content border__right__light border__left__light border__bottom__light border__top__light">
                        {/* <img src={image2}  className="card__content__image" alt="НАШІ ЗАЛИ"/> */}

                        <StaticImage src="../../../images/services.jpg" alt="НАШІ ЗАЛИ" /> 
                        <div className='card__info border__right__light border__top__light'>

                        <h3>{servicesdescription}</h3>
                        <br/>
                        <Link to="/about" style={{textDecoration: "none"}}>
                        <ButtonLinkWrapper>
                            <MyButton isBlackBg={isBlackBg}>ПЕРЕГЛЯНУТИ</MyButton>
                            <Arrow isBlackBg={true} />
                        </ButtonLinkWrapper>
                    </Link>
                        </div>  
                    </div>
                </div>
              
            </div>
    </section>
    )
}

export default About
