import React from 'react';
import { Link } from "gatsby";
import ButtonLinkWrapper from '../../../ui/btnLinkWrapper/ButtonLinkWrapper'
import MyButton from '../../../ui/button/MyButton'
import Arrow from '../../../ui/btnArrow/Arrow'
import { StaticImage } from "gatsby-plugin-image"

const Buycard = () => {
  return (
    <section className="black_bg buy_card">
        <div className="ol-container">
            <div className="grid_sides">
                <div className="side left">
                <h2>Неймовірні СУПЕРумови на всі абонементи</h2>
                <Link to="/cards">
                    <ButtonLinkWrapper>
                        <MyButton isBlackBg={true}>Придбати абонемент</MyButton>
                        <Arrow isBlackBg={true} />
                    </ButtonLinkWrapper>
                </Link>
                </div>
                <div className="side">
                    <div className="buy_card-image">
                        <StaticImage src="../../../images/olimp_cards.jpeg" alt="абонементи" />
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Buycard