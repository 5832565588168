import React from "react";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Featured from "../components/sections/featured/Featured";
import About from "../components/sections/about/About";
import SectionTitle from "../components/sections/SectionTitle";
import GreenPromoLine from "../components/sections/promoLine/GreenPromoLine";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ButtonLinkWrapper from '../ui/btnLinkWrapper/ButtonLinkWrapper'
import MyButton from '../ui/button/MyButton'
import Arrow from '../ui/btnArrow/Arrow'
import Buycard from '../components/sections/buycard/Buycard'

const Indexpage = ({ data }) => {

  return (
    <>
      <HelmetDatoCms seo={data.page.seo} favicon={data.site.favicon} />
      <Layout>
      <Featured
        title={data.page.introtitle}
        isBlackBg={true}
        featuredTitle="З 1 січня діє акція ПРИВЕДИ САНТУ"
        featuredDescription=""
        featuredBtnTxt="ВCІ НОВИНИ"
        featuredBtnURL="/news"
        image={false}
        slides={data.allPosts.nodes || []}
        carouselSlides={
          data.allDatoCmsCarouselslide.nodes
            ? data.allDatoCmsCarouselslide.nodes
            : []
        }
      />
      <Buycard />
      {/* <section style={{padding: `70px  30px 70px 30px`, position: `releative`, background: `white`}}>
        <h3 style={{textAlign: `center`, color: `black`, width: `100%`, maxWidth: `780px`, margin: `0 auto`}}>
            {
              data.page.warning
            }
        </h3>
      </section> */}
      <GreenPromoLine text="Oлімп - фітнес мережа твоїх результатів" />
      <About
        isBlackBg={true}
        sectionTitle={data.page.aboutustitle}
        sectionTitleDesc={data.page.aboutusdescription}
        // sectiontitleBtnTxt={data.page.aboutusmorebtn}
        // sectiontitleBtnUrl="/about"
        clubstitle={data.page.clubstitle}
        clubsdescription={data.page.clubsdescription}
        servicestitle={data.page.servicestitle}
        servicesdescription={data.page.servicesdescription}
      />
      <section id="news" className="black_bg">
        <SectionTitle
          isBlackBg={true}
          title={"Новини"}
          description={"Дізнавайся першим найгарячіші події і пропозиції!"}
          url="/news"
          urlBtnTxt={`всі новини`}
        />

        <div className="news-grid--wrapper">
          <div className="news-grid">
            {data.allPosts.nodes.map((post, index) => {
            return (
              <div key={index} className="news-grid-item">
                <Link
                  to={`/news/${post.slug}`}
                  style={{ textDecoration: "none", color: `inherit` }}
                >
                  <div className="image-wrapper">
                    <div className="post-container">
                      <GatsbyImage
                        image={getImage(post.coverImage.gatsbyImageData)}
                        alt={post.title + "image"}
                      />
                    </div>
                    <div className="content">
                        <h2 style={{color: `white`}}>{post.title}</h2>
                      <p className="details">{post.excerpt}</p>
                      <ButtonLinkWrapper>
                        <MyButton isBlackBg={true}>ДЕТАЛЬНІШЕ</MyButton>
                        <Arrow isBlackBg={true} />
                      </ButtonLinkWrapper>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
          </div>
        </div>
      </section>
      <section id="clubs" className="light_bg">
        <SectionTitle
          isBlackBg={false}
          title={data.page.ourclubstitle}
          description={data.page.ourclubsdescription}
          // url="/clubs"
          // urlBtnTxt={data.page.ourclubsmorebtn}
        />
      </section>
    </Layout>
    </>
  )
}

export default Indexpage


export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    page: datoCmsIndexpage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      introtitle

      aboutusdescription
      aboutusmorebtn
      aboutustitle

      clubsdescription
      clubstitle

      servicesdescription
      servicestitle
      warning

      ourclubstitle
      ourclubsdescription
      ourclubsmorebtn
    }
    allDatoCmsCarouselslide {
      nodes {
        slideimage {
          gatsbyImageData
        }
        navigatelinkto {
          uri
        }
        name
      }
    }
    allPosts: allDatoCmsPost(sort: { fields: data, order: DESC }, limit: 3) {
      nodes {
        title
        slug
        excerpt
        coverImage {
          gatsbyImageData(width: 960)
        }
      }
    }
  }
`;
