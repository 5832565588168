import React from "react";
import { Link } from "gatsby";
// import MyButton from "../../../ui/button/MyButton";
// import Arrow from "../../../ui/btnArrow/Arrow";
// import ButtonLinkWrapper from "../../../ui/btnLinkWrapper/ButtonLinkWrapper";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Featured = ({
  title,
  // featuredBtnURL,
  // featuredBtnTxt,
  isBlackBg,
  // featuredTitle,
  // featuredDescription,
  carouselSlides,
  // slides,
}) => {

  const checkNavigateTo = (slide)=>{
      if (slide.navigatelinkto) {
        return <Link to={slide.navigatelinkto.uri}>
        <GatsbyImage image={getImage(slide.slideimage.gatsbyImageData)} alt={slide.title + `img`} />
        </Link>
      }
      return <div>
        <GatsbyImage image={getImage(slide.slideimage.gatsbyImageData)} alt={slide.title + `img`} />
      </div>

  }
  return (
    <section id="featured" className={isBlackBg ? "black_bg" : "light_bg"}>
    
      {/* <Carousel  autoPlay={true} infiniteLoop={true} interval={4000} showArrows={false} swipeable showStatus={false}>
        {slides.map((slide, i) => {
          return (
            <div
              key={i}
              className={
                isBlackBg
                  ? "section__container border__bottom__white"
                  : "section__container border__bottom__light"
              }
            >
              <div
                className={
                  isBlackBg
                    ? "section__left__side section__side__padding border__right__white"
                    : "section__left__side section__side__padding border__right__light"
                }
              >
                <div
                  style={{
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `space-between`,
                    padding: `16px`,
                    maxWidth: `410px`,
                  }}
                >

                  <Link to={`/news/${slide.slug}`} style={{textDecoration: `none`}}>
                    <h2
                      className={
                        isBlackBg ? "txt__color__white" : "txt__color__black"
                      }
                      style={{ marginBottom: `26px` }}
                    >
                      {slide.title}
                    </h2>
                  </Link>
                  <Link
                    to={featuredBtnURL ? featuredBtnURL : "/"}
                    style={{ textDecoration: "none", display: `block` }}
                  >
                    <ButtonLinkWrapper>
                      <MyButton isBlackBg={isBlackBg}>
                        {featuredBtnTxt}
                      </MyButton>
                      <Arrow isBlackBg={isBlackBg} />
                    </ButtonLinkWrapper>
                  </Link>
                </div>
              </div>
              <Link to={`/news/${slide.slug}`} className="section__right__side section__side__padding">
                <GatsbyImage image={getImage(slide.coverImage.gatsbyImageData)} alt={slide.title + `img`} />
              </Link>
            </div>

        
          );
        })}
      </Carousel> */}

      <Carousel  autoPlay={true} infiniteLoop={true} interval={4000} showArrows={false} swipeable showStatus={false}>
        {carouselSlides?.map((slide, i) => {
          return (
            <div
              key={i}
              style={{width: `100%`}}
            >
              {checkNavigateTo(slide)}
            </div>
          );
        })}
      </Carousel> 

        <h1
          className={
            isBlackBg
              ? "txt__color__white border__bottom__white"
              : "txt__color__white border__bottom__light"
          }
        >
          {title}
        </h1>
    </section>
  );
};

export default Featured;
